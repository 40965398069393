import React from 'react'
import { graphql, useStaticQuery, Link } from 'gatsby'
import Img from "gatsby-image"
import { Card, Button, CardColumns} from "react-bootstrap"
// import styled from 'styled-components'


const Sponsors = () => {
    const data = useStaticQuery(graphql`
      query SponsorsComponentQuery {
        allMarkdownRemark(filter: {fileAbsolutePath: {regex: "/(sponsors)/"  }}) {
            edges {
                node {
                frontmatter {
                    title
                    image {
                        id
                        childImageSharp {
                          fluid(maxWidth: 600, quality: 100) {
                            ...GatsbyImageSharpFluid
                          }
                        }
                      }
                    }
                    html
                }
            }
        }
      }
    `)
  
    const sponsorsData = data.allMarkdownRemark.edges
    return (
        <div>
            <CardColumns>
                {sponsorsData.map(({ node }) => {
                    return (
                        <Card key={node.frontmatter.title}>
                            <Card.Header>
                            {node.frontmatter.image ? <Img fluid={node.frontmatter.image.childImageSharp.fluid} alt={node.frontmatter.name} /> : ''}
                            </Card.Header>
                            <Card.Body>
                            <div dangerouslySetInnerHTML = {{ __html: node.html }} />
                            </Card.Body>
                        </Card>
                    )
                })}
            </CardColumns>
        </div>
    )
  }
  
  export default Sponsors